import { MenuService } from '@workflow-solutions/siteflow-section-layout';
import { menuStructure } from '@workflow-solutions/siteflow-section-layout';

const rootMenuStructure = menuStructure.map(topItem => ({
    ...topItem,
    items: [{ items: [{ title: topItem.title, name: topItem.name }] }]
}));

MenuService.setMenuItemsVisibility(rootMenuStructure);

export default rootMenuStructure;
